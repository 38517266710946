//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import axios from 'axios'
	export default {
	  data(){
		return {
			list:[],
			imgArr:[
				{
				url: require('../../assets/one.png')
				},{
				url: require('../../assets/tow.png')
				},{
				url: require('../../assets/code.png')
				}
			],
			teachers:[
				{
				url: require('../../assets/teachers.png')
				},{
				url: require('../../assets/classroom.png')
				},{
				url: require('../../assets/seat.png')
				}
			],
			attend:[
				{
				url: require('../../assets/student.png')
				},{
				url: require('../../assets/train.png')
				},{
				url: require('../../assets/attend.png')
				},{
				url: require('../../assets/wall.png')
				},{
				url: require('../../assets/cultivate.png')
				}
			]
		}
	  },
		mounted(){
			this.getList(true)
		},
	  methods:{
			getIndex(){
				this.$router.push('sing-out')
			},
			detailClick(id){
				this.$router.push({
					path: 'index-ordedr-list',
					name:'index-ordedr-list',
					params: {
						orderId:id,
					}
				})
			},
			getList(clear){
				axios.get('https://milai.sdmlswkj.com/api/62b91666b0c20',{params:{p:1,z:4}}).then(res => {
					if(clear){
						this.list = []
					}
					res.data.data.list.forEach(item => {
						this.list.push(item)
					})
				}).catch(err => {
					console.log(err)
				})
			},
			examineClick(){
				this.$router.push('../index/index-main')
			},
		}
	}
  